import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FF_ENABLE_DISCOUNT_CODE } from '@/constants/featureFlags';
import { GoogleAnalyticsEventName } from '@/constants/types';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import AddPromoCode from '@/pages/components/AddPromoCode';
import type { GTMType, TrackingConfigType } from '@/providers/TrackigTypes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import type { TextType } from '../../FormType';
import ReactMarkdown from '../../components/markdown/ReactMarkdown';
import { VisuallyHiddenText } from '../../styled';
import { ButtonWrapper, SectionWrapper, StyledButton } from './styled';

export type SectionProps = {
  id: number;
  pageTitle: string;
  title: string;
  content: TextType[];
  onNextStep: () => void;
  buttonHref?: string;
  buttonText?: string;
  previousStepLabel: string;
  trackPageLoad?: boolean;
  trackingConfig?: TrackingConfigType;
  trackingData: GTMType;
};

const Section = ({
  pageTitle,
  title,
  content,
  onNextStep,
  buttonHref,
  buttonText,
  id,
  previousStepLabel,
  trackPageLoad = true,
  trackingConfig,
  trackingData
}: SectionProps) => {
  const [searchParams] = useSearchParams();
  const { flags, loading } = useFeatureFlags();
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const ffEnableDiscountCode = flags[FF_ENABLE_DISCOUNT_CODE];
  const isBookAppointment = buttonText === 'Book consultation now';

  useEffect(() => {
    if (trackPageLoad) {
      const { event, stepName, ...rest } = trackingData;
      const promocode = searchParams.get('promocode');
      const addPromoCodeToTracking = [
        GoogleAnalyticsEventName.PRESCREENING_COMPLETE as string,
        GoogleAnalyticsEventName.PRECONSULT_COMPLETE as string
      ].includes(event);
      // Incubeta gave feedback to send the last known successful step number
      const previousStepArray = Array.from(JSON.parse(sessionStorage.getItem(previousStepLabel) || `[0, 1]`));
      const failedStepIndex = Number(previousStepArray.pop()) + 1;
      const stepNumber = Number(trackingConfig?.showPreviousStepId ? failedStepIndex : id);

      const trackingPayload = {
        step_number: stepNumber.toString(),
        step_name: stepName,
        ...(promocode && addPromoCodeToTracking ? { promocode } : {}),
        ...rest
      };

      sendGoogleAnalyticsEvent(event, trackingPayload);
    }
  }, [
    sendGoogleAnalyticsEvent,
    trackingData,
    trackPageLoad,
    searchParams,
    previousStepLabel,
    trackingConfig?.showPreviousStepId,
    id
  ]);

  return (
    <SectionWrapper>
      <VisuallyHiddenText component="h1">{pageTitle}</VisuallyHiddenText>
      <Typography variant="h6" component="h2" sx={{ mt: 2.5 }}>
        {title}
      </Typography>

      {isBookAppointment && <CheckCircleIcon color="success" sx={{ height: '1.75rem', width: '1.75rem', mb: -2 }} />}
      {content.map((item, index) => (
        <ReactMarkdown key={index}>{item.value}</ReactMarkdown>
      ))}
      {isBookAppointment && ffEnableDiscountCode && <AddPromoCode />}

      <ButtonWrapper>
        {!loading && (
          <StyledButton
            size="large"
            fullWidth
            variant="contained"
            href={buttonHref}
            onClick={onNextStep}
            id={trackingData.clickId}
          >
            {buttonText}
          </StyledButton>
        )}
      </ButtonWrapper>
    </SectionWrapper>
  );
};

export default Section;
