import { WithSnowplow } from '@montugroup/data-collection';
import { Box, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import FormBuilder from '@/components/form-builder/FormBuilder';
import settings from '@/constants/constants';
import { FF_DISABLE_CONSULTATION_BUTTONS, FF_ENABLE_SNOWPLOW } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import metadata from '@/data/pre-screening.json';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import SystemUnavailable from '@/pages/SystemUnavailable';
import { restoreObject } from '@/utils/restoreObject';

import Calendar from './Calendar';
import { WithPageWrapper } from './components/Branding';

type Props = {
  urlParams?: string | Record<string, string> | URLSearchParams;
  callback?: (event: string, data: FieldValues) => void;
  onSubmit?: (data: FieldValues, error: unknown) => void;
  pageWrapper?: boolean;
};

export default function PreScreeningForm({ urlParams, callback, onSubmit, pageWrapper = true }: Props) {
  const { variant } = useParams<{ variant?: string }>();
  const isVariant = variant === '1';
  const [showCalendar, setShowCalendar] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [searchParams] = useSearchParams();
  const { flags, loading } = useFeatureFlags();

  // keeps this code intact for future a/b testing
  const chosenMetadata = isVariant ? metadata : metadata;

  const devmodeShowCalendar = !!searchParams.get('cal');

  const promocode = searchParams.get('promocode');
  const preloadCalendarAsHidden = !!emailAddress && promocode;

  const queryParams = new URLSearchParams(urlParams || location.search);
  const { eventType, step, hasSubmittedForm, ...formData } = Object.fromEntries(queryParams);

  const handleShowCalendar = () => {
    setShowCalendar(true);
  };

  useEffect(() => {
    if (hasSubmittedForm && formData) {
      const restoreData = restoreObject(formData) as FieldValues;

      setEmailAddress(restoreData?.email_and_privacy?.email.toLowerCase() || '');
    }
  }, [hasSubmittedForm, formData]);

  const handleCallback = useCallback(
    (event: string, data: FieldValues) => {
      // Get email address and store it in state to be used in Calendar booking form autofill
      const email = data?.email_and_privacy?.email.toLowerCase();

      if (email) {
        setEmailAddress(email);
      }

      if (callback) {
        callback(event, data);
      }
    },
    [callback]
  );

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  const disableConsultations = flags[FF_DISABLE_CONSULTATION_BUTTONS] ?? ffDefaults[FF_DISABLE_CONSULTATION_BUTTONS];

  if (disableConsultations) {
    return <SystemUnavailable />;
  }

  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW] ?? ffDefaults[FF_ENABLE_SNOWPLOW];
  const { postUrl, ...rest } = chosenMetadata;

  return WithPageWrapper(
    WithSnowplow(
      <>
        {!showCalendar && !devmodeShowCalendar && (
          <FormBuilder
            {...(rest as any)}
            activeStep={step}
            defaultValues={eventType === 'restore' ? formData : undefined}
            callback={handleCallback}
            postUrl={`${settings.url}${postUrl}`}
            handleShowCalendar={handleShowCalendar}
            hasSubmittedForm={hasSubmittedForm}
            onSubmit={onSubmit}
          />
        )}
        {(showCalendar || preloadCalendarAsHidden || devmodeShowCalendar) && (
          <Calendar showCalendar={showCalendar || devmodeShowCalendar} emailAddress={emailAddress} />
        )}
      </>,
      {
        appId: 'pre-screening',
        trackerName: 'poc-1'
      },
      enableSnowplow
    ),
    pageWrapper
  );
}
