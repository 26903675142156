import { Button } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';

export const SectionWrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  paddingBottom: '6rem',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    paddingBottom: 0,
    display: 'block'
  },

  ['p > a']: {
    color: 'rgb(255, 93, 42)'
  }
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  padding: '1.5rem',
  width: '100%',
  left: 0,
  bottom: 0,

  [theme.breakpoints.up('md')]: {
    position: 'inherit',
    padding: '1.5rem 0 0 0'
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,

  [theme.breakpoints.up('md')]: {
    position: 'inherit',
    width: 'fit-content'
  }
}));
