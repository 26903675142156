import mixpanel from 'mixpanel-browser';

import Logger from '@/utils/logger';

const logger = new Logger('mixpanel.ts');

function configureMixPanel({ mixpanelKey, mixpanelApiUrl }: { mixpanelKey: string; mixpanelApiUrl: string }) {
  let initialised = false;

  const init = () => {
    if (mixpanelKey && mixpanelApiUrl) {
      logger.debug('Initializing Mixpanel');

      mixpanel.init(mixpanelKey, {
        debug: true,
        api_host: mixpanelApiUrl
      });
      initialised = true;
    }
  };

  init();

  return (eventName: string, data: unknown = {}) => {
    if (initialised) {
      logger.debug(`Tracking Event: ${eventName}`);
      logger.dir(data);

      mixpanel.track(eventName, {
        [`Data ${eventName}`]: data
      });
    } else {
      init();
    }
  };
}

export default configureMixPanel;
