import EditIcon from '@mui/icons-material/Edit';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, IconButton, ListItem, TextField, Typography, styled, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import type { FilterOptionsState } from '@mui/material/useAutocomplete';
import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useGooglePlacesProvider from './Providers/GooglePlacesProvider';

type TextFieldVariant = TextFieldProps['variant'];

type AddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

type PlaceDetails = {
  address_components: AddressComponents[];
};

type ManualAddressFnType = (() => void) | undefined;

export type AddressDataType = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  street_number: string;
  city: string;
  subpremise: string;
};

export type LocationInputProps = {
  name: string;
  value: string;
  id: string;
  label?: string;
  shrinkLabel?: boolean;
  className?: string;
  disableClearable?: boolean;
  disableBorder?: boolean;
  manualAddressFn?: () => void;
  editIcon?: boolean;
  style?: React.CSSProperties;
  variant?: TextFieldVariant;
  googlePlacesApiKey: string;
  onSelectedAddress: (address: AddressDataType) => void;
  onReset?: () => void;
};

const fillInAddressComponents = (addressComponents: AddressComponents[]) =>
  addressComponents.reduce<AddressDataType>(
    (acc, component) => {
      const [componentType] = component.types;
      switch (componentType) {
        case 'locality':
          return { ...acc, suburb: component.long_name };

        case 'administrative_area_level_1':
          return { ...acc, state: component.short_name };

        case 'administrative_area_level_2':
          return { ...acc, city: component.short_name };

        case 'postal_code':
          return { ...acc, postcode: component.long_name };

        case 'route':
          return { ...acc, address: component.long_name };

        case 'street_number':
          return { ...acc, street_number: component.long_name };

        case 'subpremise':
          return { ...acc, subpremise: component.long_name };

        default:
          return acc;
      }
    },
    {
      address: '',
      suburb: '',
      city: '',
      state: '',
      postcode: '',
      street_number: '',
      subpremise: ''
    }
  );

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'disableBorder'
})<{ disableBorder: boolean }>(({ disableBorder }) => ({
  ...(disableBorder && {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
    '& .MuiOutlinedInput-input': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textDecorationColor: 'black',
      textUnderlineOffset: '3px'
    }
  })
}));

const customOption: google.maps.places.AutocompletePrediction = {
  description: '',
  place_id: 'manual-entry-option',
  structured_formatting: {
    main_text: 'Can’t find address?',
    secondary_text: 'Enter address manually',
    main_text_matched_substrings: [{ length: 0, offset: 0 }]
  },
  types: [''],
  matched_substrings: [],
  terms: []
};

const customFilterOptions = (
  options: google.maps.places.AutocompletePrediction[],
  state: FilterOptionsState<google.maps.places.AutocompletePrediction>,
  manualAddressFn: ManualAddressFnType = undefined
): google.maps.places.AutocompletePrediction[] => {
  const filtered = options.filter((option) =>
    option.description.toLowerCase().includes(state.inputValue.toLowerCase())
  );

  if (manualAddressFn && !filtered.some((option) => option.place_id === 'manual-entry-option')) {
    filtered.unshift(customOption);
  }

  return filtered;
};

export function LocationInput({
  name,
  value,
  label,
  shrinkLabel = undefined,
  className,
  disableClearable = false,
  disableBorder = false,
  manualAddressFn = undefined,
  editIcon = false,
  style,
  id,
  onSelectedAddress,
  onReset,
  googlePlacesApiKey,
  variant = 'outlined'
}: LocationInputProps) {
  const [selectedAddress, setSelectedAddress] = useState<PlaceDetails | null>(null);
  const [error, setError] = useState<string>('');
  const theme = useTheme();

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init
  } = usePlacesAutocomplete({
    debounce: 300,
    callbackName: 'initMap',
    requestOptions: {
      componentRestrictions: { country: 'au' },
      types: ['address']
    },
    initOnMount: false
  });

  const { loading } = useGooglePlacesProvider({ onLoad: () => init(), googlePlacesApiKey });

  const options = !data.length ? { freeSolo: true, autoSelect: true } : {};

  const validateAddress = (result: PlaceDetails) => {
    if (!result.address_components.find((c) => c.types.includes('street_number'))) {
      setError('Invalid address, please type street number.');
    }
  };

  const fillInAddress = (result: PlaceDetails) => {
    onSelectedAddress(fillInAddressComponents(result.address_components));
  };

  useEffect(() => {
    if (selectedAddress) {
      validateAddress(selectedAddress);
      fillInAddress(selectedAddress);
    }
  }, [selectedAddress]);

  const resetForm = () => {
    clearSuggestions();
    setValue('');
    setError('');
    setSelectedAddress(null);
    if (onReset) {
      onReset();
    }
  };

  const handleSelect = async (addressResult: google.maps.places.AutocompletePrediction | string | null) => {
    if (!addressResult) {
      resetForm();
      return;
    }

    if (typeof addressResult === 'object' && addressResult !== null && 'place_id' in addressResult) {
      if (addressResult.place_id === customOption.place_id) {
        try {
          manualAddressFn?.();
          return;
        } catch (error) {
          console.log(`${error} failed to fire manual address func`);
        }
      }
    }

    const address = typeof addressResult === 'string' ? addressResult : addressResult.description;

    if (options.freeSolo) {
      onSelectedAddress({
        suburb: '',
        city: '',
        state: '',
        postcode: '',
        street_number: '',
        address: '',
        subpremise: ''
      });
      return;
    }

    if (address) {
      try {
        const results = await getGeocode({ address });
        if (results.length > 0) {
          resetForm();
          setSelectedAddress(results[0]);
        }

        clearSuggestions();
      } catch (e) {
        if (e instanceof Error) {
          console.error('Error during Google Geocode API request', e);
        }
        console.error('Unexpected error during Google Geocode API request');
      }
    }
  };

  return (
    <div>
      <Autocomplete
        data-testid="autocomplete"
        {...options}
        disabled={loading}
        id={id}
        className={className}
        disableClearable={disableClearable}
        style={style}
        value={value}
        options={status === 'OK' ? data : []}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        size="small"
        filterOptions={
          manualAddressFn ? (options, state) => customFilterOptions(options, state, manualAddressFn) : undefined
        }
        onChange={(_event, newValue) => {
          handleSelect(newValue);
        }}
        renderInput={(params) => (
          <StyledTextField
            name={name}
            label={label}
            variant={variant}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            style={error ? { border: '1px solid red' } : {}}
            error={!!error}
            className={className}
            disableBorder={disableBorder}
            {...params}
            InputLabelProps={{ ...params.InputLabelProps, shrink: shrinkLabel }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {editIcon && (
                    <InputAdornment position="end">
                      <IconButton sx={{ color: theme.palette.secondary.dark, width: '16px', height: '16px' }}>
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              )
            }}
          />
        )}
        renderOption={(props, option) => {
          if (manualAddressFn && option.place_id === 'manual-entry-option') {
            return (
              <ListItem
                {...props}
                key="manual-entry-option"
                id="manual-entry-option"
                onClick={manualAddressFn}
                role="option"
                tabIndex={0}
              >
                <Typography
                  variant="subtitle2"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="18px"
                  onClick={manualAddressFn}
                >
                  {option.structured_formatting.main_text} <b>{option.structured_formatting.secondary_text}</b>
                </Typography>
              </ListItem>
            );
          }

          return (
            <ListItem {...props} key={option.place_id} role="option">
              {option.description}
            </ListItem>
          );
        }}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

export default LocationInput;
