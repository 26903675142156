import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined
} from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import type {} from '@mui/material/themeCssVarsAugmentation';
import type { Id, ToastContainerProps, ToastOptions, TypeOptions } from 'react-toastify';
import { ToastContainer as ToastifyContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

type ToastBodyProps = {
  title?: string;
  message: string;
};

type ToastProps = string | ToastBodyProps;

type ToastFunction = (props: ToastProps, options?: ToastOptions) => Id;

// this overrides toastify styling and maps them with the MUI theming cssvar
const StyledToastContainer = styled(Box)(({ theme }) => ({
  '--toastify-color-info': (theme.vars || theme).palette.info.main,
  '--toastify-color-success': (theme.vars || theme).palette.success.main,
  '--toastify-color-warning': (theme.vars || theme).palette.warning.main,
  '--toastify-color-error': (theme.vars || theme).palette.error.main,
  '--toastify-color-progress-info': (theme.vars || theme).palette.info.main,
  '--toastify-color-progress-success': (theme.vars || theme).palette.success.main,
  '--toastify-color-progress-warning': (theme.vars || theme).palette.warning.main,
  '--toastify-color-progress-error': (theme.vars || theme).palette.error.main
}));

// custom markup that supports title and message
const ToastBody: React.FC<ToastBodyProps> = ({ title, message }) => (
  <Box>
    {title && (
      <Typography component="h2" variant="body1">
        {title}
      </Typography>
    )}
    <Typography variant="body2">{message}</Typography>
  </Box>
);

// using @mui/icons-material for the icons
const ToastIcons: Record<TypeOptions, React.ReactNode> = {
  info: <InfoOutlined color="info" />,
  success: <CheckCircleOutlineOutlined color="success" />,
  warning: <ReportProblemOutlined color="warning" />,
  error: <ErrorOutlineOutlined color="error" />,
  default: null
};

// Higher order function to rebind react-toastify methods, apply custom body render and icon map
const createToastFunction =
  (toastMethod: typeof toastify.success | typeof toastify.error | typeof toastify.warn): ToastFunction =>
  (props: ToastProps, options?: ToastOptions): Id =>
    toastMethod(<ToastBody {...(typeof props === 'string' ? { message: props } : props)} />, {
      // apply the mui icons instead of the defaults
      icon: ({ type }: { type: TypeOptions }) => ToastIcons[type] || null,
      ...options
    });

/**
 * A component that abstracts the toast and ToastContainer functionality from `react-toastify`.
 * It applies a custom rendered body using MUI components that inherits the MUI System for setting up color schemes.
 *
 * For detailed documentation on how to use react-toastify, see: https://fkhadra.github.io/react-toastify/introduction
 * @returns ToastContainer
 */
export const ToastContainer = (props: ToastContainerProps) => (
  <StyledToastContainer>
    <ToastifyContainer {...(props as any)} />
  </StyledToastContainer>
);

/**
 * Rewraps all the toast methods to inject custom toast body
 *
 * @returns Toast
 *
 */
const toast = Object.assign((options: ToastProps) => createToastFunction(toastify)(options), {
  info: createToastFunction(toastify.info),
  success: createToastFunction(toastify.success),
  error: createToastFunction(toastify.error),
  warn: createToastFunction(toastify.warn),
  clearWaitingQueue: toastify.clearWaitingQueue,
  dismiss: toastify.dismiss,
  done: toastify.done,
  isActive: toastify.isActive,
  pause: toastify.pause,
  play: toastify.play,
  update: toastify.update
});

export default toast;
