import {
  Box,
  ButtonGroup,
  Button as MuiButton,
  Table as MuiTable,
  Pagination,
  Paper,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  styled
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import type {
  Cell,
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  VisibilityState
} from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { memo, useEffect, useMemo, useState } from 'react';

import type { LabelDisplayedRowsArgs, SxProps, Theme } from '@mui/material';
import type { CSSProperties, ReactNode } from 'react';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/consistent-type-definitions
  interface ColumnMeta<TData extends RowData, TValue> {
    /**
     * applied to the `style` prop of the table header cell if present
     */
    style?: CSSProperties;
    /**
     * applied to the `sx` prop of the table header cell if present
     */
    sx?: SxProps<Theme>;
  }
}

enum SortDir {
  asc = 'asc',
  desc = 'desc'
}

export type PaginationModel = {
  // page index
  page: number;
  // page size
  pageSize: number;
};

type TableStyleType = {
  isScrollable?: boolean;
  maxHeight?: number;
  /**
   * when true, sets css `table-layout:fixed`,
   * you MUST define column width for all your cells if this is set to true
   */
  isTableLayoutFixed?: boolean;
};

export enum PaginationVariant {
  /**
   * Display a simple pagination with selectable page size
   */
  VARIABLE_PAGE_SIZE = 'Variable Page Size',
  /**
   * Display a pagination control that allows you to go to specific page.
   * This option does not have a selectable page size control
   */
  FIXED_PAGE_SIZE = 'Fixed Page Size',
  /**
   * Display controls with only Prev and Next buttons, as the page size is unknown.
   * This is useful for cursor like APIs. You may optionally pass in a `hasNextPage` function
   * to tell the component whether the "next" button should be disabled
   */
  UNKNOWN_PAGE_SIZE = 'Unknown page size'
}

export enum PaginationLabelVariant {
  /**
   * Default
   * Display the rows label as "x-y of z"
   * x = start row, y = end row, z = total rows
   */
  ROW = 'row',
  /**
   * Display the pages label as "x of y"
   * x = current page, y = total pages
   */
  PAGE = 'page'
}

export type TableProps<TData extends RowData> = {
  className?: string;
  // data to display to the table
  data: TData[];
  // columns using tanstack table ColumnDef
  columns: ColumnDef<TData>[] | unknown; // TODO - Adding 'unknown' to columns type to bypass some type errors. Will be addressed later.
  // indicator to display skeleton loaders
  isLoading?: boolean;
  /**
   * Use the elevation prop to establish hierarchy through the use of shadows.
   * The Paper component's default elevation level is 1.
   * The prop accepts values from 0 to 24.
   * The higher the number, the further away the Paper appears to be from its background
   */
  elevation?: number;
  // number of skeleton loaders
  skeletonCount?: number;
  // height of the skeleton loaders
  skeletonHeight?: number;
  /**
   * if defined, it will limit the number of rows per page. This is not a controlled prop, and will
   * not trigger a state change on the parent.
   */
  pageSize?: number;
  // callback when clicking cell in row
  onClickRow?: (cell: Cell<TData, unknown>, row: Row<TData>) => void;
  // this will set the table density to "small" | "medium"
  compact?: boolean;
  // global filter string
  globalFilter?: string;
  // enable pagination
  showPagination?: boolean;
  paginationVariant?: PaginationVariant;
  // toggle between row and page label
  paginationLabelVariant?: PaginationLabelVariant;
  // An element at the footer of table
  FooterLeftElement?: ReactNode;
  /**
   * show page summary. This will also allow page size selection
   */
  showPaginationSummary?: boolean;
  // number of pages
  pageCount?: number;
  // manually managing the pagination i.e server side
  total?: number;
  // text to display when no data is found
  noDataFoundMessage?: string;
  // An object of column accessors and booleans to define which columns are hidden. false = hidden
  columnVisibility?: VisibilityState | undefined;

  // callback for pagination change
  onPaginationModelChange?: ({ page, pageSize }: PaginationModel) => void;

  // callback for sorting
  onSortingChange?: (state: SortingState) => void;

  // set this to true if doing server side sorting
  manualSorting?: boolean;

  // set an initial sort
  initialSort?: SortingState;

  /**
   * used to manage row selection
   */
  rowSelection?: RowSelectionState;

  onRowSelectionChange?: OnChangeFn<RowSelectionState>;

  /**
   * set this to `true` when you're using server pagination instead of client side pagination (default).
   * You MUST also provide the following props, otherwise it'll have unexpected effect.
   * - `total` - Total number of rows to display.
   * - `onPaginationModelChange` - Use this to trigger the request to the server
   */
  manualPagination?: boolean;
  /**
   * list of page sizes that you can set for pagination. Default is [10, 25, 50, 100]
   */
  pageSizeOptions?: number[];
  /**
   * Defaults to `true`, which will show different background colours on alternating rows.
   * Set this to `false` to make all rows the same colour
   */
  hasRowBackgroundColor?: boolean;

  /**
   * Only used when pagination variant is `UNKNOWN_PAGE_SIZE`. When provided, this is
   * what's used to determine whether to disable the next page or not
   */
  hasNextPage?: boolean;

  shouldResetPageIndex?: boolean;

  /**
   * Function that can be used to apply styles to a particular row.
   * Called with the current row at render.
   * @param row
   */
  applyRowStyles?: (row: Row<TData>) => { [key: string]: unknown };
} & TableStyleType;

const PaginationContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 1rem 0 1rem'
});

const getPaginationSummary = (total = 0, pageIndex = 0, pageSize = 50, rowCount = 0) => {
  if (rowCount === 0 || total === 0) {
    return {
      summaryItemsCountFrom: 0,
      summaryItemsCountTo: 0,
      summaryPageTotal: total || 0
    };
  }
  const summaryItemsCountFrom = pageIndex * pageSize + 1;
  const summaryItemsCountTo = rowCount < pageSize ? rowCount : (pageIndex + 1) * pageSize;
  const summaryPageTotal = Math.ceil(total / pageSize);

  return {
    summaryItemsCountFrom,
    summaryItemsCountTo,
    summaryPageTotal
  };
};

export function Table<TData extends RowData>({
  className,
  data,
  columns,
  isLoading,
  elevation = 1,
  globalFilter,
  skeletonCount = 10,
  skeletonHeight = 28,
  pageSize = 10,
  pageCount,
  total = 100,
  showPagination = false,
  showPaginationSummary = true,
  onClickRow,
  compact = false,
  onPaginationModelChange,
  onSortingChange,
  manualSorting = false,
  initialSort = [],
  hasRowBackgroundColor = true,
  isScrollable = false,
  maxHeight = 300,
  rowSelection,
  onRowSelectionChange,
  noDataFoundMessage = 'No data found',
  columnVisibility,
  manualPagination,
  pageSizeOptions,
  paginationVariant = PaginationVariant.VARIABLE_PAGE_SIZE,
  paginationLabelVariant = PaginationLabelVariant.ROW,
  FooterLeftElement,
  isTableLayoutFixed,
  hasNextPage,
  shouldResetPageIndex,
  applyRowStyles
}: TableProps<TData>) {
  const [sorting, setSorting] = useState<SortingState>(initialSort);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize
  });

  // TODO - Adding 'unknown' to columns type to bypass some type errors. Will be addressed later.
  const memoizedColumns = useMemo<ColumnDef<TData>[] | unknown>(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const {
    getHeaderGroups,
    getRowModel,
    getAllColumns,
    setPageIndex,
    getState,
    setPageSize,
    getRowCount,
    resetPageIndex
  } = useReactTable({
    data: memoizedData || [],
    columns: memoizedColumns as ColumnDef<TData>[],
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize
      },
      columnVisibility
    },
    state: {
      pagination: paginationState,
      globalFilter: globalFilter?.trim(),
      sorting,
      rowSelection
    },
    pageCount,
    enableSorting: true,
    manualSorting,
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPaginationState,
    manualPagination
  });

  const { summaryItemsCountFrom, summaryItemsCountTo, summaryPageTotal } = getPaginationSummary(
    manualPagination ? total : getRowCount(),
    getState().pagination.pageIndex,
    getState().pagination.pageSize,
    getRowCount()
  );

  const skeletons = Array.from({ length: skeletonCount }, (_x, i) => i);

  const columnCount = getAllColumns().length;

  const noDataFound = !isLoading && (!memoizedData || memoizedData.length === 0 || getRowModel().rows.length < 1);

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const changedPageSize = Number(e.target.value);
    setPageSize(changedPageSize);
    if (onPaginationModelChange) {
      onPaginationModelChange({
        page: getState().pagination.pageIndex,
        pageSize: changedPageSize
      });
    }
  };

  const handlePageChange = (changedPage: number) => {
    setPageIndex(changedPage);
    if (onPaginationModelChange) {
      onPaginationModelChange({
        page: changedPage,
        pageSize: getState().pagination.pageSize
      });
    }
  };

  const handleLabelDisplayedRows = ({ from, to, count, page }: LabelDisplayedRowsArgs) => {
    // https://mui.com/material-ui/api/table-pagination/
    if (paginationLabelVariant === PaginationLabelVariant.ROW) {
      return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    }

    const currentPage = page + 1;
    const lastPage = Math.ceil(count / getState().pagination.pageSize);
    return `${currentPage} of ${lastPage}`;
  };

  useEffect(() => {
    if (pageSize) {
      setPageSize(pageSize);
    }
  }, [pageSize, setPageSize]);

  useEffect(() => {
    if (onSortingChange) {
      onSortingChange(sorting);
    }
  }, [onSortingChange, sorting]);

  // needs to reset the table state when new data is set
  useEffect(() => {
    if (!total && manualPagination) {
      resetPageIndex();
    }
  }, [data, resetPageIndex, total, manualPagination]);

  useEffect(() => {
    if (shouldResetPageIndex) {
      resetPageIndex();
    }
  }, [data, shouldResetPageIndex, resetPageIndex]);

  return (
    <Paper sx={{ pb: 4, borderRadius: 2 }} className={className} elevation={elevation}>
      <TableContainer
        sx={{
          borderBottom: hasRowBackgroundColor ? 1 : 'none',
          borderColor: 'grey.300',
          maxHeight: isScrollable ? maxHeight : 'auto'
        }}
      >
        <MuiTable
          size={compact ? 'small' : 'medium'}
          stickyHeader={isScrollable}
          sx={{
            tableLayout: isTableLayoutFixed ? 'fixed' : 'auto'
          }}
        >
          <TableHead data-testid="table-heading">
            {getHeaderGroups().map((group) => (
              <TableRow key={group.id}>
                {group.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={header.column.columnDef.meta?.style}
                    sx={header.column.columnDef.meta?.sx}
                  >
                    {header.isPlaceholder ? null : (
                      <TableSortLabel
                        active={sorting?.[0]?.id === header.id}
                        direction={header.column.getIsSorted() === SortDir.asc ? SortDir.asc : SortDir.desc}
                        onClick={header.column.getToggleSortingHandler()}
                        hideSortIcon={!header.column.columnDef.enableSorting}
                        disabled={header.column.columnDef.enableSorting === false}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {sorting?.[0]?.id === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {header.column.getIsSorted() === SortDir.desc ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody data-testid="table-content">
            {!isLoading ? (
              getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ...(hasRowBackgroundColor
                      ? {
                          '&:nth-of-type(odd)': {
                            bgcolor: 'action.hover'
                          }
                        }
                      : {}),
                    ...(applyRowStyles ? applyRowStyles(row) : {})
                  }}
                  hover
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} scope="row" onClick={() => onClickRow?.(cell, row)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <>
                {skeletons.map((skeleton) => (
                  <TableRow key={skeleton}>
                    {Array.from({ length: columnCount }, (_x, i) => i).map((elm) => (
                      <TableCell key={elm}>
                        <Skeleton height={skeletonHeight} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </MuiTable>
        {noDataFound && (
          <Box my={2} textAlign="center">
            {noDataFoundMessage}
          </Box>
        )}
      </TableContainer>
      {showPagination && (
        <PaginationContainer
          justifyContent={
            paginationVariant === PaginationVariant.FIXED_PAGE_SIZE ||
            (paginationVariant === PaginationVariant.VARIABLE_PAGE_SIZE && Boolean(FooterLeftElement))
              ? 'space-between'
              : 'flex-end'
          }
        >
          {paginationVariant === PaginationVariant.VARIABLE_PAGE_SIZE && (
            <>
              {FooterLeftElement}
              <TablePagination
                component="div"
                count={manualPagination ? total : getRowCount()}
                page={getState().pagination.pageIndex}
                rowsPerPage={getState().pagination.pageSize}
                onRowsPerPageChange={handlePageSizeChange}
                onPageChange={(_, page: number) => handlePageChange(page)}
                rowsPerPageOptions={pageSizeOptions}
                labelDisplayedRows={handleLabelDisplayedRows}
              />
            </>
          )}
          {paginationVariant === PaginationVariant.FIXED_PAGE_SIZE && (
            <>
              {showPaginationSummary && (
                <Typography>
                  Showing <b>{summaryItemsCountFrom}</b> to <b>{summaryItemsCountTo}</b> of{' '}
                  <b>{manualPagination ? total : getRowCount()}</b>
                </Typography>
              )}
              <Pagination
                count={summaryPageTotal}
                page={getState().pagination.pageIndex + 1}
                onChange={(_, page: number) => handlePageChange(page - 1)}
                variant="outlined"
                shape="rounded"
              />
            </>
          )}
          {paginationVariant === PaginationVariant.UNKNOWN_PAGE_SIZE && (
            <ButtonGroup variant="outlined">
              {/* Design system buttons doesn't currently render ButtonGroup button styles properly */}
              <MuiButton
                disabled={getState().pagination.pageIndex <= 0}
                onClick={() => handlePageChange(getState().pagination.pageIndex - 1)}
              >
                Previous
              </MuiButton>
              <MuiButton
                onClick={() => handlePageChange(getState().pagination.pageIndex + 1)}
                disabled={hasNextPage !== undefined ? !hasNextPage : false}
              >
                Next
              </MuiButton>
            </ButtonGroup>
          )}
        </PaginationContainer>
      )}
    </Paper>
  );
}

export default memo(Table);
