import * as braze from '@braze/web-sdk';
import md5 from 'crypto-js/md5';

import Logger from '@/utils/logger';

const logger = new Logger('braze.ts');

export default function init({ brazeApiKey, brazeBaseUrl }: { brazeApiKey: string; brazeBaseUrl: string }) {
  if (brazeApiKey && brazeBaseUrl) {
    logger.debug('Initializing Braze');
    braze.initialize(brazeApiKey, {
      baseUrl: brazeBaseUrl
    });

    braze.automaticallyShowInAppMessages();
    braze.openSession();
  }
}

export function changeUser(email: string) {
  try {
    const identifier = md5(email.toLowerCase()).toString();
    braze.changeUser(identifier);
    return braze.getUser();
  } catch (error) {
    logger.error(`Braze change user failed.`, error);
  }
  return null;
}

export function brazeTrackEvent(event: string, data?: object) {
  try {
    braze.logCustomEvent(event, data);
    logger.debug(`Braze[${event}]`, data);
  } catch (error) {
    logger.error('Braze event failed to send.', error);
  }
}
