import type { CalComBookingEvent, CalendarData } from '@/constants/types';
import { transformKeysToSnakeCase } from '@/utils/url';

function mapBookingEventToCalendarData({ booking }: CalComBookingEvent): CalendarData {
  if (!booking) {
    return {
      inviteeUuid: '',
      inviteeFullName: '',
      inviteeEmail: '',
      assignedTo: '',
      eventStartTime: '',
      eventName: ''
    };
  }

  const { user, responses } = booking;

  return {
    inviteeUuid: booking.uid || '',
    inviteeFullName: responses?.name || '',
    inviteeEmail: responses?.email || '',
    inviteePhoneNumber: responses?.phoneNumber || '',
    assignedTo: user?.name || '',
    eventStartTime: booking.startTime || '',
    eventName: booking.title || ''
  };
}

export function mapBookingEventToQueryString(event: CalComBookingEvent): string {
  const data = mapBookingEventToCalendarData(event);
  return new URLSearchParams(transformKeysToSnakeCase(data)).toString();
}
