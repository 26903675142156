import { useEffect, useMemo, useRef } from 'react';

import braze, { brazeTrackEvent, changeUser } from '@/providers/braze';
import datadog, { customAction } from '@/providers/datadog';
import configureGTM from '@/providers/googleTagManager';
import configureMixPanel from '@/providers/mixpanel';
import type { GTMType } from '@/providers/TrackigTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useTrackingProviders(settings: any) {
  const mpRef = useRef<(eventName: string, data?: unknown) => void>();
  const gtmRef = useRef<(id: number, data: GTMType) => void>();

  useEffect(() => {
    braze(settings);
    datadog(settings);
    gtmRef.current = configureGTM(settings);
    mpRef.current = configureMixPanel(settings);
  }, [settings]);

  return {
    braze: {
      brazeTrackEvent,
      changeUser
    },
    mpTrackEvent: useMemo(() => mpRef.current, [mpRef]),
    gtmTrackEvent: useMemo(() => gtmRef.current, [gtmRef]),
    ddTrackAction: useMemo(() => (topic: string) => customAction(topic), [])
  };
}
