import Cal, { getCalApi } from '@calcom/embed-react';
import { Box, Paper, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import settings from '@/constants/constants';
import type { CalComBookingEvent } from '@/constants/types';
import { CalComEventType, GoogleAnalyticsEventName } from '@/constants/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import useTrackingProviders from '@/hooks/useTrackingProviders';
import { mapBookingEventToQueryString } from '@/utils/calendar';

export type CalendarProps = {
  showCalendar: boolean;
  emailAddress: string | undefined;
};

export const StyledFullPageOverlay = styled(Paper)(({ theme: t }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  backgroundImage: 'url(/assets/MobileMotif.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  height: '100%',
  width: '100%',
  padding: '5rem 0 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [t.breakpoints.up('md')]: {
    backgroundImage: 'url(/assets/DesktopMotif.svg)',
    backgroundSize: 'cover'
  }
}));

export default function Calendar(props: CalendarProps) {
  const { showCalendar, emailAddress } = props;
  const [showBookingSuccessOverlay, setShowBookingSuccessOverlay] = useState(false);
  const [calendarConfig, setCalendarConfig] = useState({});
  const { mpTrackEvent, ddTrackAction } = useTrackingProviders(settings);
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const [searchParams] = useSearchParams();

  const calActionCounter = useRef(0);

  const calendarDisplayStyle = showCalendar ? 'block' : 'none';
  const promocode = searchParams.get('promocode');
  // const { mode } = useColorScheme();
  // const theme = mode && mode === 'dark' ? 'dark' : 'light';

  useEffect(() => {
    (async function () {
      const CalComCalendar = await getCalApi();
      CalComCalendar('ui', {
        // theme,
        hideEventTypeDetails: false,
        layout: 'month_view'
      });

      CalComCalendar('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          setShowBookingSuccessOverlay(true);
          const { data } = e.detail;
          if (mpTrackEvent) {
            mpTrackEvent(CalComEventType.bookingSuccessful, data);
          }
          const calComBookingData = data as CalComBookingEvent;
          sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.SUBMIT_BOOKING, {
            event_category: 'cal.com',
            event_label: 'Initial Consultation',
            booking_id: calComBookingData?.booking?.uid || '',
            ...(promocode ? { promocode } : {})
          });
          const existingQueryParams = window.location.search.replace('?', '');
          const bookingEventQueryParams = mapBookingEventToQueryString(calComBookingData);
          setTimeout(() => {
            window.location.replace(`${settings.paymentsUrl}?${bookingEventQueryParams}&${existingQueryParams}`);
          }, 1000); // delay of 1000ms to allow for the event to be sent to Google Analytics
        }
      });

      CalComCalendar('on', {
        action: '__windowLoadComplete',
        callback: (e) => {
          const { data } = e.detail;
          if (mpTrackEvent) {
            mpTrackEvent(CalComEventType.__windowLoadComplete, data);
          }
          if (ddTrackAction) {
            ddTrackAction('calendar window load completed');
          }
          sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.START_BOOKING, {
            event_category: 'cal.com',
            event_label: 'Initial Consultation',
            ...(promocode ? { promocode } : {})
          });
        }
      });

      CalComCalendar('on', {
        action: '__routeChanged',
        callback: () => {
          if (ddTrackAction) {
            const actionMessage =
              calActionCounter.current >= 1 ? 'calendar route changed' : 'calendar initial route changed';
            ddTrackAction(actionMessage);
            calActionCounter.current = calActionCounter.current + 1;
          }
        }
      });
    })();
  }, [mpTrackEvent, sendGoogleAnalyticsEvent, ddTrackAction, promocode]);

  const phoneNumber = searchParams.get('phoneNumber') ?? '+61';
  const email = emailAddress || searchParams.get('email');
  const name = searchParams.get('name');

  // Unfortunately, the Calcom 'identifier' doesn't allow snake_case format, else these
  // utm values could easily be passed automatically to their respective hidden input
  // fields on the Initial Consult booking form with no extra code. Instead, we have to
  // re-map these utm values to the unique Calcom camelCase 'identifiers' so they are passed
  // to the backend via the Calcom webhook. These values are used by the marketing team.
  const utmCampaign = searchParams.get('utm_campaign');
  const utmTerm = searchParams.get('utm_term');
  const utmMedium = searchParams.get('utm_medium');
  const utmSource = searchParams.get('utm_source');
  const utmContent = searchParams.get('utm_content');

  useEffect(() => {
    setCalendarConfig((prevState) => ({
      ...prevState,
      ...Object.fromEntries(searchParams),
      ...(email ? { email } : {}),
      ...(name ? { name } : {}),
      ...(utmCampaign ? { utmCampaign } : {}),
      ...(utmTerm ? { utmTerm } : {}),
      ...(utmMedium ? { utmMedium } : {}),
      ...(utmSource ? { utmSource } : {}),
      ...(utmContent ? { utmContent } : {}),
      ...(promocode ? { promocode } : {}),
      phoneNumber
    }));
  }, [searchParams, phoneNumber]);

  return (
    <>
      {showBookingSuccessOverlay && (
        <StyledFullPageOverlay>
          <CircularProgress color="primary" size="5rem" />
        </StyledFullPageOverlay>
      )}
      <Box sx={{ display: calendarDisplayStyle }} data-testid={`calendar-display-${calendarDisplayStyle}`}>
        <Cal
          calLink={settings.calendar.calLink}
          calOrigin={settings.calendar.calOrigin}
          config={calendarConfig}
          embedJsUrl={settings.calendar.embedJsUrl}
        ></Cal>
      </Box>
    </>
  );
}
