import { BrandThemeProvider } from '@montugroup/themes';
import { createContext, useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom';

import { FFLoadingStateProvider } from '@/components/featureFlags/FFLoadingStateProvider';
import settings from '@/constants/constants';
import { getAppEnv, getFormConfig } from '@/constants/env';
import HomeLinks from '@/pages/HomeLinks';
import HomeRedirect from '@/pages/HomeRedirect';
import PreConsultationForm from '@/pages/PreConsultationForm';
import PreScreeningForm from '@/pages/PreScreeningForm';
import QualityOfLifeForm from '@/pages/QualityOfLifeForm';
import Layout from '@/pcp/components/Layout';
import pcpSettings from '@/pcp/constants/constants';
import Error from '@/pcp/content/Error';
import PCPortal from '@/pcp/pages/PCPortal';
import configureGooglePlaces from '@/providers/googlePlaces';
import flattenObject from '@/utils/flattenObject';
import Logger from '@/utils/logger';

import useTrackingProviders from './hooks/useTrackingProviders';

type LogEventsContextType = { logEvents: (event: string, data: FieldValues) => void };
export const LogEventsContext = createContext<LogEventsContextType>({
  logEvents: () => undefined
});

const logger = new Logger('App.tsx');

const Error404 = () => (
  <Layout contentMaxWidth="md">
    <Error type="404" />
  </Layout>
);

function App() {
  const appEnv = getAppEnv();
  const isProduction = appEnv === 'production';
  const targetForm = getFormConfig();

  const { braze, mpTrackEvent } = useTrackingProviders(settings);

  configureGooglePlaces(settings);

  function logEvents(event: string, data: FieldValues) {
    if (isProduction) {
      const flattenData = flattenObject(data);
      const isConsultationForm = event.includes('pre-consultation');
      const email = isConsultationForm ? data?.email.toLowerCase() : data?.email_and_privacy?.email.toLowerCase();
      const payload = new URLSearchParams({ eventType: 'restore', ...flattenData }).toString();
      try {
        if (!email) {
          return;
        }

        const brazeUser = braze.changeUser(email);

        if (!brazeUser) {
          return;
        }

        brazeUser.setEmail(email);

        if (isConsultationForm) {
          if (data?.consent_form?.first_name) {
            brazeUser.setFirstName(data.consent_form.first_name);
          }
        }
      } catch (e) {
        logger.error(e);
      }
      // https://montugroup.atlassian.net/browse/MM-162
      // generate a url for braze to allow patient to come back and continue filling the forms
      braze.brazeTrackEvent(event, { url: `${window.location.origin}/?${payload}` });

      if (mpTrackEvent) {
        mpTrackEvent(event, data);
      }
    }
  }

  const logEventsContextValue: LogEventsContextType = useMemo(() => ({ logEvents }), [logEvents]);

  function getRootComponent() {
    switch (targetForm) {
      case 'screening':
        return <PreScreeningForm callback={logEvents} />;
      case 'consult':
        return <PreConsultationForm callback={logEvents} />;
      case 'portal':
        return <HomeRedirect />;
      case 'all':
        if (!isProduction) {
          return <HomeLinks />;
        }
        return <HomeRedirect />;
    }
  }

  return (
    <BrandThemeProvider>
      <FFLoadingStateProvider>
        <LogEventsContext.Provider value={logEventsContextValue}>
          <Routes>
            {/* Root (homepage) - dependent on `VITE_FORM_CONFIG` */}
            <Route path={`/${targetForm === 'screening' ? ':variant?' : ''}`} element={getRootComponent()} />

            {/* Pre-Consultation Portal path */}
            {(targetForm === 'portal' || targetForm === 'all') && (
              <>
                <Route path={`${pcpSettings.frontendPath}/:token?`} element={<PCPortal />} />
                <Route
                  path={settings.qualityOfLifeForm.frontendPath}
                  element={<QualityOfLifeForm formType="standalone" />}
                />
              </>
            )}

            {/* Form paths */}
            {targetForm === 'all' && (
              <>
                <Route path="/pre-screening/:variant?" element={<PreScreeningForm callback={logEvents} />} />
                <Route path="pre-consultation" element={<PreConsultationForm callback={logEvents} />} />
              </>
            )}

            {/* All other unmatched paths */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </LogEventsContext.Provider>
      </FFLoadingStateProvider>
    </BrandThemeProvider>
  );
}

export default App;
