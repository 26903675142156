import { ArrowDropDown } from '@mui/icons-material';
import { MenuItem, MenuList, alpha } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

type MenuItem = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

type MenuButtons = {
  children: ReactNode;
  isDisabled?: boolean;
};

type DropdownMenuProps = {
  buttonLabel: string;
  menuItems: MenuButtons[] | MenuItem[];
  handleClickMenuItem?: (value: string) => void;
} & Pick<PopperProps, 'placement'> &
  Pick<ButtonProps, 'variant' | 'color' | 'sx'>;

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  '& .MuiMenuItem-root:not(:last-child)': {
    borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`
  },
  '& .MuiMenuItem-root:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  }
}));

export function DropdownMenu({
  buttonLabel,
  menuItems,
  handleClickMenuItem,
  placement = 'bottom-end',
  variant = 'outlined',
  color = 'primary',
  sx
}: DropdownMenuProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const onClickMenuItem = (event: Event | React.SyntheticEvent, value: string) => {
    if (!handleClickMenuItem) {
      return;
    }
    handleClose(event);
    handleClickMenuItem(value);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        sx={{ textTransform: 'unset', paddingRight: '0.5rem', ...sx }}
        ref={anchorRef}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label={buttonLabel}
        onClick={handleToggle}
        variant={variant}
        color={color}
      >
        {buttonLabel}
        <ArrowDropDown />
      </Button>
      <Popper
        sx={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map((item, i) => (
                    <MenuItem
                      key={i}
                      disabled={item.isDisabled}
                      onClick={(e) => onClickMenuItem(e, (item as MenuItem).value ?? '')}
                    >
                      {(item as MenuButtons)?.children ?? (item as MenuItem)?.label}
                    </MenuItem>
                  ))}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DropdownMenu;
