import { WithSnowplow } from '@montugroup/data-collection';
import type { FieldValues } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import FormBuilder from '@/components/form-builder/FormBuilder';
import settings from '@/constants/constants';
import { FF_ENABLE_SNOWPLOW } from '@/constants/featureFlags';
import metadata from '@/data/pre-consultation.json';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import { WithPageWrapper } from './components/Branding';

type Props = {
  urlParams?: string | Record<string, string> | URLSearchParams;
  callback?: (event: string, data: FieldValues) => void;
  onSubmit?: (data: FieldValues, error: unknown) => void;
  pageWrapper?: boolean;
};

export default function PreConsultationForm({ urlParams, callback, onSubmit, pageWrapper = true }: Props) {
  const location = useLocation();
  const { flags } = useFeatureFlags();
  const { postUrl, ...rest } = metadata;

  const queryParams = new URLSearchParams(urlParams || location.search);

  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { eventType, step, ...formData } = Object.fromEntries(queryParams);

  const defaultFormData = { ...(formData?.email && { email: formData?.email }) };

  return WithPageWrapper(
    WithSnowplow(
      <FormBuilder
        {...(rest as any)}
        activeStep={step}
        defaultValues={eventType === 'restore' ? formData : defaultFormData}
        callback={callback}
        postUrl={`${settings.url}${postUrl}`}
        onSubmit={onSubmit}
      />,
      {
        appId: 'pre-consultation',
        trackerName: 'testing'
      },
      enableSnowplow
    ),
    pageWrapper
  );
}
